import { SOVRYN_INDEXER } from '../constants/infrastructure';
import { BOB } from '../constants/infrastructure/bob';
import { SEPOLIA } from '../constants/infrastructure/sepolia';
import { Environments } from '../types/global';
import { isBobChain, isRskChain } from './chain';
import { isMainnet } from './helpers';
export const getIndexerUri = (chainId) => BOB.indexer[isMainnet() ? Environments.Mainnet : Environments.Testnet];
export const getSovrynIndexerUri = (chainId) => {
    if (isBobChain(chainId) || isRskChain(chainId)) {
        return SOVRYN_INDEXER[isMainnet() ? Environments.Mainnet : Environments.Testnet];
    }
    return SEPOLIA.indexer.testnet;
};
